import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserTableComponent } from './user-table/user-table.component';
import { UserManagementDataService } from './user-management.data-service';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { UserTableHeaderComponent } from './user-table-header/user-table-header.component';
import { SharedModule } from '../../shared/shared.module';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    UserTableComponent,
    UserTableHeaderComponent,
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatPaginatorModule,
    MatRippleModule,
    MatCardModule,
    MatInputModule,
    SharedModule,
    MatIconModule,
  ],
  providers: [
    UserManagementDataService,
  ],
  exports: [
    UserTableComponent,
    UserTableHeaderComponent,
  ],
})
export class UserManagementModule {}
