import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CollaboratorDataService } from '../../../collaborator/collaborator.data-service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-admin-menu',
  template: `
    <div class="background-accent admin-menu">
      <div class="background-accent" id="admin-menu-header">
        <div id="first-line">
          <app-eqopack-brand
            data-testid="eqopack-brand"
            id="app-eqopack-brand">
          </app-eqopack-brand>
          <button
            (click)="closeMenu.emit()"
            class="mat-elevation-z2"
            data-testid="close-button"
            id="close-menu"
            mat-icon-button

          >
            <mat-icon data-testid="close-button-icon">menu</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <app-select-version></app-select-version>
        <h2 data-testid="admin-menu-title" i18n id="admin-menu-title">Administration</h2>
        <mat-nav-list>
          <mat-list-item *ngFor="let link of adminLinks">
            <a
              [routerLink]="link.link"
              i18n
              matLine
              routerLinkActive="color-primary-important"
            >{{ link.label }}</a>
          </mat-list-item>
        </mat-nav-list>
        <h2 id="eqopack-menu-title" data-testid="eqopack-menu-title">eQopack</h2>
        <mat-nav-list>
          <mat-list-item>
            <a
              data-testid="project-menu-item"
              i18n
              matLine
              routerLink="projects"
              routerLinkActive="color-primary-important"
            >Project modeling</a>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <app-made-by class="background-accent" data-testid="made-by" id="made-by"></app-made-by>
    </div>
  `,
  styleUrls: [ './admin-menu.component.scss' ],
})
export class AdminMenuComponent implements OnInit, OnDestroy {
  private readonly subscription = new Subscription();

  adminLinks: { link: string, label: string }[] = environment.features.portfolio ?
    [ { link: 'scenario-library', label: 'Scenario Library' }, { link: 'portfolios', label: 'Portfolios' } ] :
    [ { link: 'scenario-library', label: 'Scenario Library' }, ];

  userManagementFeatureLink = { link: 'user-management', label: 'User management' };

  @Output()
  closeMenu: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly collaboratorDataService: CollaboratorDataService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.collaboratorDataService.getMe().subscribe(me => {
        if (me?.features?.userManagement?.enabled) {
          this.adminLinks = [ ...this.adminLinks, this.userManagementFeatureLink ];
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
