import { Injectable } from '@angular/core';
import { ProjectAssessment } from '../models/project-assessment.model';
import { AbstractMapper } from '../../abstract-mapper';
import { MeasureWarningMapperService } from './measure-warning-mapper.service';
import { ScenarioSingleScoreAssessmentMapperService } from './scenario-single-score-assessment-mapper.service';
import { WasteStreamAssessmentMapperService } from './waste-stream-assessment-mapper.service';
import { ScenarioPackagingDesignAssessmentMapperService } from './scenario-packaging-design-assessment-mapper.service';
import { ScenarioLcaAssessmentMapperService } from './scenario-lca-assessment-mapper.service';
import { ScenarioPlasticFootprintAssessmentMapperService } from './scenario-plastic-footprint-assessment-mapper.service';
import { GHGIndicator } from '../models/ghg-indicator.model';
import { ScenarioMaterialCircularityAssessmentMapperService } from './scenario-material-circularity-assessment-mapper.service';
import { ProjectAssessmentDto } from '../../../api/dtos/__generated__/projectAssessmentDto';

@Injectable({
  providedIn: 'root',
})
export class ProjectAssessmentMapperService extends AbstractMapper<ProjectAssessmentDto, ProjectAssessment> {

  constructor(
    private readonly measureWarningMapper: MeasureWarningMapperService,
    private readonly wasteStreamAssessmentMapper: WasteStreamAssessmentMapperService,
    private readonly singleScoreAssessmentMapper: ScenarioSingleScoreAssessmentMapperService,
    private readonly scenarioPackagingDesignAssessmentMapper: ScenarioPackagingDesignAssessmentMapperService,
    private readonly scenarioLcaAssessmentMapper: ScenarioLcaAssessmentMapperService,
    private readonly scenarioMaterialCircularityAssessmentMapper: ScenarioMaterialCircularityAssessmentMapperService,
    private readonly scenarioPlasticFootprintAssessmentMapper: ScenarioPlasticFootprintAssessmentMapperService,
  ) {
    super();
  }

  from(dto: ProjectAssessmentDto): ProjectAssessment {
    const {
      warnings = [],
      plasticFootprintAssessments = [],
      wasteStreamAssessments = [],
      singleScoreAssessments = [],
      packagingDesignAssessments = [],
      lcaAssessments = [],
      ghgAssessments = [],
      materialCircularityAssessments = [],
      projectId,
      version,
    } = dto;

    return {
      warnings: this.measureWarningMapper.fromList(warnings),
      plasticFootprintAssessments: this.scenarioPlasticFootprintAssessmentMapper.fromList(plasticFootprintAssessments),
      wasteStreamAssessments: this.wasteStreamAssessmentMapper.fromList(wasteStreamAssessments),
      singleScoreAssessments: this.singleScoreAssessmentMapper.fromList(singleScoreAssessments),
      packagingDesignAssessments: this.scenarioPackagingDesignAssessmentMapper.fromList(packagingDesignAssessments),
      lcaAssessments: this.scenarioLcaAssessmentMapper.fromList(lcaAssessments),
      ghgAssessments: this.scenarioLcaAssessmentMapper
        .fromList(ghgAssessments?.map(a => ({ ...a, overrideIndicator: GHGIndicator.CC })) ?? []),
      materialCircularityAssessments: this.scenarioMaterialCircularityAssessmentMapper.fromList(materialCircularityAssessments ?? []),
      projectId,
      version: {
        displayName: version.displayName,
        hash: version.hash,
      },
    };
  }
}
