import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Collaborator } from './collaborator.model';
import { HttpClient } from '@angular/common/http';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { CollaboratorMapperService } from './collaborator-mapper.service';
import { CollaboratorListDto } from '../api/dtos/__generated__/collaboratorListDto';
import { excludeMe, excludeOwner } from './exclude-collaborator';

@Injectable({
  providedIn: 'root',
})
export class CollaboratorDataService {
  COLLABORATOR_URL = `${environment.apiUrl}/collaborators`;
  me: Collaborator;

  constructor(
    private readonly http: HttpClient,
    private readonly mapper: CollaboratorMapperService,
  ) { }

  get(): Observable<Collaborator[]> {
    return this.http.get(this.COLLABORATOR_URL).pipe(
      map((dto: CollaboratorListDto) =>
        this.mapper.fromList(dto.collaborators),
      ),
    );
  }

  getAllExceptMeAndOwner(ownerId: string): Observable<Collaborator[]> {
    return combineLatest([ this.get(), this.getMe() ]).pipe(
      map(([ collaborators, me ]) => collaborators
        .filter(excludeMe(me))
        .filter(excludeOwner(ownerId)),
      ));
  }

  getById(collaboratorId: string): Observable<Collaborator> {
    return this.http.get(`${this.COLLABORATOR_URL}/${collaboratorId}`).pipe(
      map(dto => this.mapper.from(dto)),
    );
  }

  getMe(): Observable<Collaborator> {
    if (this.me) {
      return of(this.me);
    }
    return this.http.get(`${this.COLLABORATOR_URL}/me`).pipe(
      map(dto => this.mapper.from(dto)),
      tap(me => this.me = me),
    );
  }

  isAdmin(): Observable<boolean> {
    return this.getMe().pipe(
      pluck('isAdmin'),
      catchError(_ => of(false)),
    );
  }
}
