import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CollaboratorDataService } from '../collaborator/collaborator.data-service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserManagementGuard implements CanActivate {

  constructor(
    private readonly collaboratorDataService: CollaboratorDataService,
    private readonly router: Router) {
  }

  canActivate(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.collaboratorDataService.getMe()
      .pipe(
        map(me => {
          if (!me?.features?.userManagement?.enabled) {
            this.router.navigate([ '' ]);
          }
          return true;
        }),
      );
  }

}
