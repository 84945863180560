import { Injectable } from '@angular/core';
import { AbstractMapper } from '../../project/abstract-mapper';
import { User } from './user.model';
import { UserType } from '@aws-sdk/client-cognito-identity-provider';

@Injectable({
  providedIn: 'root',
})
export class UserMapperService extends AbstractMapper<UserType, User> {

  private readonly ADMIN_ROLE = 'eqopack_admin';

  constructor() {
    super();
  }

  from(dto: UserType): User {
    if (!dto.Attributes || !dto.Attributes.length) {
      return;
    }
    return dto.Attributes.reduce(this.extractAttributes, {
      sub: '',
      emailVerified: false,
      firstname: '',
      lastname: '',
      email: '',
      isAdmin: false,
    } as User);
  }

  private extractAttributes = (user: User, attribute: { Name: string, Value: string }): User => {
    const { Name, Value } = attribute;
    switch (Name) {
      case 'sub':
        return { ...user, sub: Value };
      case 'email_verified':
        return { ...user, emailVerified: Value.includes('true') };
      case 'given_name':
        return { ...user, firstname: Value };
      case 'family_name':
        return { ...user, lastname: Value };
      case 'email':
        return { ...user, email: Value };
      case 'custom:ext_roles':
        return { ...user, isAdmin: Value.includes(this.ADMIN_ROLE) };
      case 'custom:ext_roles_long':
        return { ...user, isAdmin: Value.includes(this.ADMIN_ROLE) };
      default:
        return user;
    }
  };
}
