import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { AdminModule } from './admin/admin.module';
import { ProjectModelingStoreModule } from './project/project-modeling-store.module';
import { PortfolioModule } from './portfolio/portfolio.module';
import { PackagingModule } from './packaging/packaging.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    AuthModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    ProjectModelingStoreModule,
    AppRoutingModule,
    MatProgressBarModule,
    environment.production ? [] : StoreDevtoolsModule.instrument({}),
    SharedModule,
    AdminModule,
    PortfolioModule,
    PackagingModule,
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: '50%', hasBackdrop: true } },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [ Router ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [ Sentry.TraceService ],
      multi: true,
    },
  ],
  bootstrap: [ AppComponent ],
})
export class AppModule {
}
