import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UserManagementDataService } from '../../user-management/user-management.data-service';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { UserTableComponent } from '../../user-management/user-table/user-table.component';
import { User } from '../../user-management/user.model';

@Component({
  selector: 'app-user-management-page',
  templateUrl: './user-management-page.component.html',
  styleUrls: [ './user-management-page.component.scss' ],
})
export class UserManagementPageComponent implements OnInit, AfterViewInit {

  users$: Observable<User[]>;

  @ViewChild(UserTableComponent)
  userTable: UserTableComponent;
  dataSource: MatTableDataSource<User>;

  constructor(private readonly usersDataService: UserManagementDataService) { }

  ngOnInit(): void {
    this.users$ = this.usersDataService.fetchUsers();
  }

  ngAfterViewInit(): void {
    this.dataSource = this.userTable.dataSource;
  }

  applyFilter(event: Partial<Event>) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
