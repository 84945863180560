<mat-card>
  <mat-table
    *ngIf="dataSource"
    [dataSource]="dataSource"
    [trackBy]="trackBy"
    data-testid="mat-table"
    matSort
    matSortActive="lastname"
    matSortDirection="asc"
    matSortDisableClear
  >
    <ng-container matColumnDef="firstname">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Firstname</mat-header-cell>
      <mat-cell *matCellDef="let user" data-testid="firstname">{{user?.firstname}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastname">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Lastname</mat-header-cell>
      <mat-cell *matCellDef="let user" data-testid="lastname">{{user?.lastname}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Email</mat-header-cell>
      <mat-cell *matCellDef="let user" data-testid="email">{{user?.email }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="isAdmin">
      <mat-header-cell *matHeaderCellDef i18n mat-sort-header>Has admin role</mat-header-cell>
      <mat-cell *matCellDef="let user">
        <ng-container *ngIf="user?.isAdmin === undefined; else recyclableFilled"></ng-container>
        <ng-template #recyclableFilled>
          <mat-icon *ngIf="user.isAdmin; else no">done</mat-icon>
          <ng-template #no>
            <mat-icon>highlight_off</mat-icon>
          </ng-template>
        </ng-template>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="background-accent"></mat-header-row>
    <mat-row
      *matRowDef="let user; columns: displayedColumns"
      id="user-{{user.sub}}"
      matRipple
    ></mat-row>
  </mat-table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" class="table-footer"></mat-paginator>
</mat-card>
