import { Injectable } from '@angular/core';
import { Provider } from '@aws-sdk/types';
import {
  CognitoIdentityProviderClient,
  CognitoIdentityProviderClientConfig,
  ListUsersCommand,
  ListUsersCommandOutput,
  UserType,
} from '@aws-sdk/client-cognito-identity-provider';
import { environment } from '../../../environments/environment';
import { map, pluck, switchMap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';
import { UserMapperService } from './user-mapper.service';
import { User } from './user.model';
import { AuthService } from '../../auth/auth.service';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import {
  CognitoIdentityCredentials,
  fromCognitoIdentityPool,
  FromCognitoIdentityPoolParameters,
} from '@aws-sdk/credential-provider-cognito-identity';
import { CollaboratorDataService } from '../../collaborator/collaborator.data-service';

@Injectable({
  providedIn: 'root',
})
export class UserManagementDataService {

  private readonly region = environment.Auth.region;
  private readonly identityPoolId = environment.userManagement.identityPoolId;
  private readonly providerName = environment.userManagement.centralUserPoolDomain;
  private cognitoClient$: Observable<CognitoIdentityProviderClient>;

  constructor(
    private readonly usersMapperService: UserMapperService,
    private readonly collaboratorDataService: CollaboratorDataService,
    private readonly auth: AuthService,
  ) {
    this.cognitoClient$ = from(this.getClient());
  }

  fetchUsers(): Observable<User[]> {
    return this.cognitoClient$.pipe(
      switchMap(this.sendListUsersCommand),
      pluck('Users'),
      map((users: UserType[]) => this.usersMapperService.fromList(users)),
    );
  }

  private sendListUsersCommand = (client: CognitoIdentityProviderClient): Observable<ListUsersCommandOutput> => {
    return this.collaboratorDataService.getMe().pipe(
      map(me => me.features.userManagement.userPoolId),
      switchMap(userPoolId => from(client.send(new ListUsersCommand({ UserPoolId: userPoolId })))),
    );
  };

  private async getClient(): Promise<CognitoIdentityProviderClient> {
    const credentials = await this.configureCredentials();
    const config: CognitoIdentityProviderClientConfig = {
      region: this.region,
      credentials,
    };
    return new CognitoIdentityProviderClient(config);
  }

  private async configureCredentials(): Promise<Provider<CognitoIdentityCredentials>> {
    const client: CognitoIdentityClient = new CognitoIdentityClient({
      region: this.region,
    });
    const idToken = await this.auth.getIdToken();
    const logins = {};
    logins[this.providerName] = idToken;
    const config: FromCognitoIdentityPoolParameters = {
      client,
      identityPoolId: this.identityPoolId,
      logins,
    };
    return fromCognitoIdentityPool(config);
  }
}
