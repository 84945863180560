import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { User } from '../user.model';
import { UserManagementDataService } from '../user-management.data-service';

@Component({
  selector: 'app-users-table',
  templateUrl: './user-table.component.html',
  styleUrls: [ './user-table.component.scss' ],
})
export class UserTableComponent implements OnInit, AfterViewInit, OnDestroy {

  private readonly subscription = new Subscription();
  dataSource: MatTableDataSource<User>;

  displayedColumns = [
    'firstname',
    'lastname',
    'email',
    'isAdmin',
  ];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private readonly userService: UserManagementDataService) {
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<User>([]);
    this.subscription.add(
      this.userService.fetchUsers().subscribe(scenarios => {
        this.dataSource.data = scenarios;
      }),
    );
  }

  trackBy(row: User): string {
    return row.sub;
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
